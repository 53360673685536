import "../App.css";
import { useState, useEffect } from "react";
import brazil from "../resources/flag/brazil.png";
import us from "../resources/flag/united-states.png";
import IconDiv from "./Icondiv";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import MenuIcon from "@mui/icons-material/Menu";

function Navybar({ isPortuguese, setIsPortuguese, isMobile }) {
  const [scroll, setScroll] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      window.scrollY > 0 ? setScroll(true) : setScroll(false);
    }, 1);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100vw",
          color: "white",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "fixed",
            height: "10vh",
            top: "0",
            width: "100vw",
            zIndex: "1000",
            alignItems: "center",
            flexDirection: "row",
            transition: "0.4s",
            background: scroll ? "rgba(255,255,255,0.8)" : "",
            boxShadow: scroll ? "20px 20px 50px rgba(0,0,0,0.5)" : "",
            borderBottom: scroll ? "1px solid rgba(255,255,255,0.5)" : "",
            //backdropFilter: scroll ? "" : "",
            borderRadius: scroll ? "0 0 15px 15px" : "",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Mudança de DarkMode */}
            <div
              style={{
                display: "flex",
                width: "auto",
                height: "50px",
                background: "red",
                margin: "10px",
              }}
            ></div>
          </div>
          <div
          style={{
            display: "flex",
            width: "100vw",
            position: "absolute",
            alignItems: "center",
            color: "red",
            fontSize: isMobile ? "22px" : "52px",
            fontWeight: "bold",
            marginLeft: "auto",
            justifyContent: "center",
            marginRight: "5vw",
            zIndex: 1
          }}
        >
          Felipe Dias Diniz
        </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "auto",
              marginLeft: "auto",
              margin: "10px",
              zIndex: 2
            }}
          >
            {isMobile ? (
              <>
              <MenuIcon onClick={() => setOpenMenu(!openMenu)} style={{ color: "red" }}/>
              {openMenu ? <div
                style={{
                  display: "flex",
                  width: openMenu ? "auto" : "0",
                  height:  openMenu ? "auto" : "0",
                  flexDirection: "column",
                  position: "absolute",
                  background: "#333032",
                  right: 0,
                  top: 0,
                  padding: "3vh 5vw",
                  borderRadius: "0px 0px 0px 50px ",
                  alignItems: "center",
                  gap: "1vh",
                  transition: "2s",
                  zIndex: 2
                }}
              >
                <MenuIcon onClick={() => setOpenMenu(!openMenu)} style={{ color: "red" }}/>
                <IconDiv  navy={true} Icon={LinkedInIcon} path={"https://linkedin.com/in/felipe-diniz-gm"} />
                <IconDiv navy={true} Icon={GitHubIcon} path={"https://github.com/gamemastergm"} />
                <IconDiv navy={true} Icon={AlternateEmailIcon} path={"mailto:felipediasdiniz@gmail.com"} isEmail={true} />
                {isPortuguese ? (
                  <img
                    onClick={() => setIsPortuguese(false)}
                    src={brazil}
                    style={{ width: "50px", height: "50px" }}
                  />
                ) : (
                  <img
                    onClick={() => setIsPortuguese(true)}
                    src={us}
                    style={{ width: "50px", height: "50px" }}
                  />
                )}
              </div> : <></>}</>
            ) : (
              <div style={{ display: "flex", gap: "1vw"}}>
                <IconDiv navy={true} Icon={LinkedInIcon} path={"https://linkedin.com/in/felipe-diniz-gm"} />
                <IconDiv navy={true} Icon={GitHubIcon} path={"https://github.com/gamemastergm"} />
                <IconDiv navy={true} Icon={AlternateEmailIcon} path={"mailto:felipediasdiniz@gmail.com"} isEmail={true} />
                {isPortuguese ? (
                  <img
                    onClick={() => setIsPortuguese(false)}
                    src={brazil}
                    style={{ width: "50px", height: "50px" }}
                  />
                ) : (
                  <img
                    onClick={() => setIsPortuguese(true)}
                    src={us}
                    style={{ width: "50px", height: "50px" }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Navybar;
