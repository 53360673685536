import React, { useEffect, useState } from "react";
import { Particle, animate } from "../ParticulesAnimation";
import Typewriter from "../Typewriter.js";
import profile from "../resources/profile.jpeg";
import textContent from "../Textcontent.js";
import ProgressBar from "./ProgressBar.jsx";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

function Apresentation({ isPortuguese, isMobile }) {
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const canvas = document.getElementById("canvas1");
    canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let particlesArray = [];

    let mouse = {
      x: null,
      y: null,
      radius: (canvas.height / 100) * (canvas.width / 100),
    };

    window.addEventListener("mousemove", function (event) {
      mouse.x = event.x;
      mouse.y = event.y;
    });

    window.addEventListener("mouseout", function () {
      mouse.x = undefined;
      mouse.y = undefined;
    });

    function createParticle() {
      const size = Math.random() * 5 + 1;
      const x =
        Math.random() * (window.innerWidth - size * 2 - size * 2) + size * 2;
      const y =
        Math.random() * (window.innerHeight - size * 2 - size * 2) + size * 2;
      const directionX = Math.random() * 5 - 2.5;
      const directionY = Math.random() * 5 - 2.5;
      const color =
        "background: radial-gradient(circle at 25%, red, white, red);";

      return new Particle(x, y, directionX, directionY, size, color);
    }

    function initParticles() {
      const numberOfParticles = (canvas.height * canvas.width) / 9000;
      for (let index = 0; index < numberOfParticles; index++) {
        particlesArray.push(createParticle());
      }
    }

    window.addEventListener("resize", function () {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      mouse.radius = (canvas.height / 100) * (canvas.height / 100);
      initParticles();
    });

    initParticles();
    animate(canvas, particlesArray, mouse);
  }, []);

  useEffect(() => {
    if (show) {
      const timerId = setTimeout(() => {
        setShowDetails(true);
      }, 3000);

      return () => {
        setShowDetails(false);
        clearTimeout(timerId);
      };
    }
  }, [show]);

  return (
    <div
      style={{
        display: "flex",
        maxWidth: isMobile ? "95vw" : "100vw",
        height: isMobile ? "240vh" : "100vh",
        position: "relative",
        justifyContent: "center",
      }}
    >
      <canvas
        id="canvas1"
        style={{
          width: "100%",
          height: "100%",
          background: "#fdf3f9",
          position: "absolute",  
        }}
      />

      <div
        style={{
          display: "flex",
          width: "auto",
          height: "auto",
          flexDirection: isMobile ? "column" : "row",
          position: "absolute",
          justifyContent: "center",
          marginTop: "10vh",
          alignItems: "center",
        }}
      >
        <div
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          style={{
            display: "flex",
            flexDirection: "column",
            width: !show ? "auto" : "auto",
            height: show ? isMobile ? "auto" : showDetails ? "80vh" : "32vh" : "32vh",
            borderRadius: "50px",
            background: !show ? "" : "rgba(0, 0, 0, 0.5)",
            alignItems: "center",
            transition: "1s",
          }}
        >
          <div style={{ display: "flex", width: "auto", flexDirection: isMobile ? "column" : "row", alignItems: "center" }}>
            <div style={{
              width: isMobile ? "50vw" : "15vw",
              height: isMobile ? "30vh" : "32vh",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",}}>
            <img
              src={profile}
              style={{
                width: isMobile ? "50vw" : "15vw",
                height: isMobile ? "30vh" : "32vh",
                marginRight: "5vw",
                borderRadius: "15%",
                zIndex: 1,
                position: "absolute"
              }}
            />{!show ? <DoubleArrowIcon sx={{color: "#e70022", width: isMobile ? "7vw" :  "2vw", height: isMobile ? "6vh" : "4vh"}} className="arrowRight"/> :  !showDetails ? <DoubleArrowIcon sx={{color: "#e70022", width: isMobile ? "7vw" :  "2vw", height: isMobile ? "6vh" : "4vh", transform: "rotate(90deg)"}} className="arrowDown"/>:<></>}
              
              </div>
            <div
              onMouseEnter={() => setShowDetails(true)}
              onMouseLeave={() => setShowDetails(false)}
              style={{
                display: "flex",
                width: show ? isMobile ? "85vw" : "61.5vw" : "0",
                height: "auto",
                margin: "1vw",
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                transition: "1s",
              }}
            >
              {show && <a style={{textAlign: isMobile ? "justify": "", whiteSpace: isMobile ? "pre-wrap": "", fontSize: "32px"}}><Typewriter text={textContent(isPortuguese).info}/></a>}
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "100%":  "50% 50%",
              gridGap: "1vh 1vw",
              padding: "5vh",
              width: "100%",
              height: "auto",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              transitionDelay: "1s",
              transition: "1s",
              
            }}
          >
            {showDetails && <ProgressBar text={"Java"} time={"4"} isPortuguese={isPortuguese} isMobile={isMobile} />}
            {showDetails && <ProgressBar text={"SpringBoot"} time={"3"} isPortuguese={isPortuguese} isMobile={isMobile} />}
            {showDetails && <ProgressBar text={"React"} time={"2"} isPortuguese={isPortuguese} isMobile={isMobile} />}
            {showDetails && <ProgressBar text={"JavaScript"} time={"2"} isPortuguese={isPortuguese} isMobile={isMobile} /> }
            {showDetails && <ProgressBar text={"CSS"} time={"3"} isPortuguese={isPortuguese} isMobile={isMobile} />}
            {showDetails && <ProgressBar text={"HTML"} time={"3"} isPortuguese={isPortuguese} isMobile={isMobile} />}
            {showDetails && <ProgressBar text={"PostgresSQL"} time={"2"} isPortuguese={isPortuguese} isMobile={isMobile} />}
            {showDetails && <ProgressBar text={"Node.js"} time={"1"} isPortuguese={isPortuguese} isMobile={isMobile} />}
            {showDetails && <ProgressBar text={"JUnit"} time={"1"} isPortuguese={isPortuguese} isMobile={isMobile} />}
            {showDetails && <ProgressBar text={"AndroidStudio"} time={"1"} isPortuguese={isPortuguese} isMobile={isMobile} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apresentation;
