import { useInView } from "react-intersection-observer";
import Typewriter from "../Typewriter.js";

function Emblem({ img, title, time, text, leftSide, isMobile }) {
  const { ref, inView: myElementIsVisible } = useInView({rootMargin: "50px"});
  return (
    <>
      <div
        ref={ref}
        style={{
          display: "flex",
          padding: "5vh 0",
          width: isMobile ? "80vw" : "50%",
          height: isMobile ? "5vh" : "14vh",
          position: "relative",
          margin: "10vh 0",
          borderRadius: "15px",
          alignItems: "center",
          left: leftSide ? 0 : "50%",
          opacity: myElementIsVisible ? 1 : 0,
          filter: myElementIsVisible ? "blur(0)" : "blur(5px)",
          transform: leftSide
            ? myElementIsVisible
              ? "translateX(0)"
              : "translateX(-100%)"
            : myElementIsVisible
            ? "translateX(0)"
            : "translateX(100%)",
          transition: "all 1s",
          zIndex: 100,
          borderTop: "10px solid transparent",
        }}
      >
        <img
          src={img}
          style={{
            position: "absolute",
            width: isMobile ?  "16vw" : "10vw",
            height: isMobile ?  "8vh" : "20vh",
            borderRadius: "50%",
            left: !leftSide ? isMobile ? "-8vw" :"-5vw" : "37.8vw",
            right: leftSide ? "-10vw" : "",
          }}
        />
        <div
          style={{
            display: "flex",
            width: isMobile ? "auto": "100%",
            heightMax: "200%",
            flexDirection: "column",
            background: "#333032",
            overflow: "hidden",
            justifyContent: "center",
            padding: isMobile ? "5%" : "2%",
            margin: isMobile ? "" : "0 calc(100% - 34.7vw)",
            marginLeft: isMobile ? "13vw" : "7vw",
            borderRadius: "6px",
            transition: "1.5s",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: isMobile ? "10vh" : "11vh",
              zIndex: 1,
              borderTop: "30px solid transparent",
              borderLeft: !leftSide ? "30px solid #333032" : "15px solid transparent",
              borderRight: leftSide ? "30px solid #333032" : "15px solid transparent",
              //right: leftSide ? "7.2vw" : isMobile ? "calc(100% - 33vw)" : "calc(100% - 8.5vw)",
              right: leftSide ? "7.2vw" : isMobile ? "40vw" : "",
              left: !leftSide ?  "6.2vw" : isMobile ? "" : "",
              transform: !leftSide ? "rotate(45deg)" : "rotate(-45deg)",
            }}
        />
        { myElementIsVisible && <a style={{color:"#e70022", fontWeight: "bold", fontSize: isMobile ? "18px" : "32px"}}><Typewriter text={title} duration={20} /></a>}
        { myElementIsVisible && <small style={{marginBottom: "10px" , fontSize: isMobile ? "14px" : "24px"}}><Typewriter text={time} duration={20} /></small>}
        { myElementIsVisible && <a style={{fontSize: isMobile ? "15px" : "32px"}}><Typewriter text={text} duration={20} /></a>}
        </div>
      </div>
    </>
  );
}

export default Emblem;
