import "./App.css";
import { useState, useEffect  } from "react";
import Navybar from "./components/Navybar";
import Footer from "./components/Footer";
import Body from "./components/Body";

function isMobileDevice() {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

function App() {
  const [isPortuguese, setIsPortuguese] = useState(true);

  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <Navybar isPortuguese={isPortuguese} setIsPortuguese={setIsPortuguese} isMobile={isMobile} />
      <Body isPortuguese={isPortuguese} isMobile={isMobile} />
      <Footer isMobile={isMobile} />
      </>
  );
}

export default App;
