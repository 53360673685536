const TextContent = {
  personal: `Sou um Engenheiro de Software FullStack em busca de novos desafios, com desejo de refinar minhas habilidades individuais e junto a equipe atingir os objetivos colaborativamente a satisfação coletiva. Em aliança aos interesses da empresa, visando desenvolvimento mutuo.`,

  y: ` Ano`,

  yS: ` Anos`,
  
  titleProject: `Projetos`,

  pokedex: `O projeto Pokedex foi desenvolvido utilizando Node e React.js. Esta iniciativa consiste em uma Pokedex abrangente que engloba os Pokémon do universo animado, permitindo aos usuários agendar batalhas por meio de um calendário integrado, com notificações para lembrar das batalhas agendadas.
\nDentro da aplicação, os usuários têm a capacidade de gerenciar os Pokémon que já possuem, rastrear aqueles que ainda não foram capturados e explorar uma lista completa de todas as espécies de Pokémon existentes.`,

  titleAr3biai: `Ar3biai Tecnologia`,

  timeAr3biai: `2023 - Presente`,

  textAr3biai: `
  Treinamento de Usuário\n
  Desenvolvedor`,

  titleMmcz: `MMC&Zarif Advogados`,

  timeMmcz: `2021 - 2023`,

  textMmcz: `
    Backup de servidor e gerenciamento de banco de dados\n
    Manutenção de hardware de computadores e rede`,

  titleUcsal: "UCSal - Universidade Católica do Salvador",

  timeUcsal: `2020 - Presente`,

  textUcsal: `Bacharelado - Engenharia de Software`,

  titleScrum: `Scrum Foundation Professional Certificate (SFPC) - (PTBR) CertiProf`,

  timeScrum: ``,

  textScrum: `Código da credencial 75065257`,
};

export default TextContent;
