import "../App.css";
import { useInView } from "react-intersection-observer";
import Typewriter from "../Typewriter.js";
import textContent from "../Textcontent.js";
import Pokedex from "../resources/Pokedex.jpg";

function Body({ isPortuguese, isMobile }) {

  const { ref, inView: myElementIsVisible } = useInView();
  return (
    <>
      <div
        style={{
          width: "100vw",
          display: "flex",
          backgroundColor: "#fdf3f9",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "auto",
            height: "auto",
            background: "#333032",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            fontSize: "48px",
            fontWeight: "bold",
          }}
        >
          <a
            style={{
              display: "flex",
              width: "auto",
              height: "auto",
              fontSize: isMobile ? "42px" : "72px",
              textTransform: "uppercase",
            }}
          >
            {textContent(isPortuguese).titleProject}
          </a>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "auto",
            minHeight: "50vh",
            borderRadius: "15px",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <img
            src={Pokedex}
            style={{
              display: "flex",
              width: isMobile ? "250px" : "300px",
              height: isMobile ? "250px" : "300px",
              padding: "10px",
              borderRadius: "15%",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <div
            ref={ref}
            style={{
              display: "flex",
              transition: "1.5s",
              width: "80%",
              height: "auto",
              margin: "10px",
              overflow: "hidden",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {myElementIsVisible && (
              <a style={{ color: "#333032", width: "100%", height: "100%" , fontSize: "32px" }}>
                <Typewriter text={textContent(isPortuguese).pokedex} />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
