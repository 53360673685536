import "../App.css";
import Bottombar from "./Bottombar";

function Footer({ isMobile }) {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "5vh",
          position: "relative",
          flexDirection: "row"
        }}
      >
        <Bottombar isMobile={isMobile} />
      </div>
    </>
  );
}

export default Footer;
