const TextContent = {
  personal: `I am a FullStack Software Engineer actively seeking new challenges, driven by a strong desire to enhance my individual skills. I am dedicated to working collaboratively with a team to achieve our collective goals and ensure mutual satisfaction. My focus is aligned with the company's objectives, with a shared commitment to fostering mutual growth and success.`,

  y: ` Year`,
 
  yS: ` Years`,
  
  titleProject: `Projects`,

  pokedex: `The Pokedex project was developed using Node and React.js. This project serves as a comprehensive Pokedex for the Pokémon universe, enabling users to organize and schedule battles through an integrated calendar that sends notifications for upcoming engagements.
  \nThe application offers users the ability to manage their owned Pokémon, keep track of those yet to be captured, and explore an extensive catalog of all known Pokémon species.`,

  titleAr3biai: `Ar3biai Tecnologia`,

  timeAr3biai: `2023 - Present`,

  textAr3biai: `
  User Training\n
  Developer`,

  titleMmcz: `MMC&Zarif Advogados`,

  timeMmcz: `2021 - 2023`,

  textMmcz: `
  Server Backup and Database Management\n
  Computer and Network Hardware Maintenance`,

  titleUcsal: "UCSal - Universidade Católica do Salvador",

  timeUcsal: `2020 - Present`,

  textUcsal: `Bachelor's Degree - Software Engineering`,

  titleScrum: `Scrum Foundation Professional Certificate (SFPC) - CertiProf`,

  timeScrum: ``,

  textScrum: `Credential Code 75065257`,
};

export default TextContent;
