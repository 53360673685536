import PTBR from "./portuguese.js";
import ENG from "./english.js";

const TextContent = (isPortuguese) => ({ 
  
  info: isPortuguese ? PTBR.personal : ENG.personal,

  y: isPortuguese ? PTBR.y : ENG.y,

  yS: isPortuguese ? PTBR.yS : ENG.yS,

  titleProject: isPortuguese ? PTBR.titleProject : ENG.titleProject,

  pokedex: isPortuguese ? PTBR.pokedex : ENG.pokedex,

  titleAr3biai: isPortuguese ? PTBR.titleAr3biai : ENG.titleAr3biai,

  timeAr3biai: isPortuguese ? PTBR.timeAr3biai : ENG.timeAr3biai,

  textAr3biai: isPortuguese ? PTBR.textAr3biai : ENG.textAr3biai,

  titleMmcz: isPortuguese ? PTBR.titleMmcz : ENG.titleMmcz,

  timeMmcz: isPortuguese ? PTBR.timeMmcz : ENG.timeMmcz,

  textMmcz: isPortuguese ? PTBR.textMmcz : ENG.textMmcz,

  titleUcsal: isPortuguese ? PTBR.titleUcsal : ENG.titleUcsal,

  timeUcsal: isPortuguese ? PTBR.timeUcsal : ENG.timeUcsal,

  textUcsal: isPortuguese ? PTBR.textUcsal : ENG.textUcsal,

  titleScrum: isPortuguese ? PTBR.titleScrum : ENG.titleScrum,

  timeScrum: isPortuguese ? PTBR.timeScrum : ENG.timeScrum,

  textScrum: isPortuguese ? PTBR.textScrum : ENG.textScrum,
});



export default TextContent;
