import "../App.css";
import textContent from "../Textcontent.js";
import Emblem from "./Emblem";
import ucsal from "../resources/UCSAL.png";
import mmcz from "../resources/mmcz.png";
import ar3biai from "../resources/Ar3biai.jpeg";
import scrumfundation from "../resources/scrumfundation.png";
import profile from "../resources/profile.jpeg";
import Pokedex from "../resources/Pokedex.jpg";

function Achievements({ isPortuguese, isMobile }) {
  
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          minHeight: "80vh",
          margin: "5vh 0 0 0",
          flexDirection: "row",
          background: "#fdf3f9",
          //alignItems: "center",
        }}
      >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "relative",
              flexDirection: "column",
              overflowY: "scroll",
              scrollbarWidth: "none",
              scrollBehavior: "smooth",
              paddingLeft:  isMobile ? "9vw": "10vw",
              margin: "auto",
              marginLeft: "0",
            }}
          >
            <div className="timeline" 
              style={{
                  position: "relative",
                  maxWidth: isMobile ? "0" : "95%",
                  height:  isMobile ? "580%": "250%",
                  marginTop: "5vh",
                  paddingTop: "15vh",
                  zIndex: 0,      
              }}
  >
            <Emblem class="hidden" img={ucsal} title={textContent(isPortuguese).titleUcsal} time={textContent(isPortuguese).timeUcsal} text={textContent(isPortuguese).textUcsal} leftSide={isMobile ? false : true} isMobile={isMobile}/>
            <Emblem class="hidden" img={mmcz} title={textContent(isPortuguese).titleMmcz} time={textContent(isPortuguese).timeMmcz} text={textContent(isPortuguese).textMmcz} leftSide={false} isMobile={isMobile}/>
            <Emblem class="hidden" img={scrumfundation} title={textContent(isPortuguese).titleScrum} time={textContent(isPortuguese).timeScrum} text={textContent(isPortuguese).textScrum} leftSide={isMobile ? false : true} isMobile={isMobile}/>
            <Emblem class="hidden" img={Pokedex} title={"Pokedex"} time={"2022"} text={`github.com/\ngamemastergm/\nPokedex`} leftSide={false} isMobile={isMobile}/>
            <Emblem class="hidden" img={ar3biai} title={textContent(isPortuguese).titleAr3biai} time={textContent(isPortuguese).timeAr3biai} text={textContent(isPortuguese).textAr3biai} leftSide={isMobile ? false : true} isMobile={isMobile}/>
            <Emblem class="hidden" img={profile} title={"NEW PROJECT"} time={"SOON"} text={"UNKNOW"} leftSide={false} isMobile={isMobile}/>
            </div>
        </div>
        {/*<div style={{
            display: "flex",
          width: "30vw",
          height: "100%",
          flexDirection: "column",
          margin: "10px",
          alignItems: "center",
          justifyContent: "center",}}>

        <div style={{ display: "flex", width: "100%", fontSize: "40px", margin: "5px",
           left: 0,alignItems: "center",}}>
            Engenheiro de Software
          </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            height: "80vh",
            backgroundColor: "#D91A1A",
            //backgroundColor: "#1C2326",
            borderRadius: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></div>
        </div>*/}
      </div>
    </>
  );
}

export default Achievements;
