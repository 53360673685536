import React, { useEffect, useState, useRef } from "react";

const Typewriter = ({ text, duration }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const currentPositionRef = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPosition((value) => value + 1);
      currentPositionRef.current += 1;

      if (currentPositionRef.current > text.length) {
        clearInterval(intervalId);
      }
    }, duration);

    return () => {
      clearInterval(intervalId);
      currentPositionRef.current = 0;
      setCurrentPosition(0);
    };
  }, [text, duration]);

  const renderTextWithLineBreaks = () => {
    const textParts = text.substring(0, currentPosition).split("\n");
    return textParts.map((part, index) => (
      <span key={index}>
        {part}
        {index < textParts.length - 1 && <br />}
      </span>
    ));
  };

  return <a>{renderTextWithLineBreaks()}</a>;
};

export default Typewriter;
