import "../App.css";
import Apresentation from "./Apresentation";
import Projects from "./Projects";
import Achievements from "./Achievements";

function Body({ isPortuguese, isMobile }) {
  return (
    <>
      <div
        style={{
          width: "auto",
          height: "auto",
          color: "white",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Apresentation isPortuguese={isPortuguese} isMobile={isMobile} />
        <Projects isPortuguese={isPortuguese} isMobile={isMobile}/>
        <Achievements isPortuguese={isPortuguese} isMobile={isMobile} />
      </div>
    </>
  );
}

export default Body;
