import "../App.css";
import { useInView } from "react-intersection-observer";
import textContent from "../Textcontent.js";

function ProgressBar({ text, time, isPortuguese, isMobile }) {
  const { ref, inView: myElementIsVisible } = useInView();
  return (
    <>
      <div style={{ width: isMobile ? "80%" : "100%", maxWidth: isMobile ? "70vw" : "30vw", margin:"0 5vw",  }}>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#fff",
            textTransform: "uppercase",
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {text}
        </div>
        <div
          style={{
            position: "relative",
            height: "2vh",
            background: "#591515",
            borderRadius: "3px",
            zIndex: 5,
          }}
        ></div>
        <div
          ref={ref}
          style={{
            height: "2vh",
            width: myElementIsVisible ? "100%" : "0%",
            maxWidth: time === "4" ? "100%" : time === "3" ? "75%" : time === "2" ? "50%": time === "1" ? "25%" : "0%",
            top: "-2vh",
            position: "relative",
            background: "#D91A1A",
            borderRadius: "3px",
            zIndex: 10,
            transition: "3s",
          }}
        >
          <div
            style={{
              width: "1vw",
              height: "1vh",
              position: "absolute",
              padding: "1vh 1vw",
              background: "#D92938",
              borderRadius: "2px",
              fontSize: "12px",
              justifyContent: "center",
              alignItems: "center",
              top: "-4.2vh",
              right: 0,
              transform: "translateX(50%)",
              whiteSpace: "nowrap",
              zIndex: 10,
            }}
          >
            {time}
            {time > 1 ? textContent(isPortuguese).y : textContent(isPortuguese).yS}
          </div>
          <div
            style={{
              width: "auto",
              height: "0.1vh",
              position: "absolute",
              padding: "1vh 0.5vw",
              background: "#D92938",
              borderRadius: "2px",
              fontSize: "12px",
              top: "-2.8vh",
              right: "-0.5vw",
              transform: "rotate(45deg)",
              zIndex: 9,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ProgressBar;
