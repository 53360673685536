import Textdiv from ".//Textdiv";

function IconDiv({ text, Icon, navy, path, isEmail }) {
  return (
    <div style={{ display: "flex", alignItems: "center", wordBreak: "break-word",}}>
    <a
      style={{ display: "flex", color: "inherit",  alignItems: "center", width: "100%",  }}
      target="_blank"
      href={isEmail ? `mailto:${path}` : path}
    >
      <Icon
        sx={{
          color: navy ? "black" : "white",
          fontSize: navy ? "30px" : "25px",
        }}
      />
      <div style={{ color: "#D91A1A", wordBreak: "break-word", }}>{text}</div>
    </a>
  </div>
  );
}

export default IconDiv;
