class Particle {
  constructor(x, y, directionX, directionY, size, color) {
    this.x = x;
    this.y = y;
    this.directionX = directionX;
    this.directionY = directionY;
    this.size = size;
    this.color = color;
  }
  draw(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
  
    const gradient = ctx.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.size);
    gradient.addColorStop(0, 'black');  
    gradient.addColorStop(1, '#e70022');  
    
    ctx.fillStyle = gradient;
    ctx.fill();
  }
  update(canvas, mouse) {
    if (this.x > canvas.width || this.x < 0) {
      this.directionX = -this.directionX;
    }
    if (this.y > canvas.height || this.y < 0) {
      this.directionY = -this.directionY;
    }

    let dx = mouse.x - this.x;
    let dy = mouse.y - this.y;
    let distance = Math.sqrt(dx * dx + dy * dy);
  
    if (distance < mouse.radius + this.size) {
      let angle = Math.atan2(dy, dx);
      let targetX = mouse.x - Math.cos(angle) * (mouse.radius + this.size);
      let targetY = mouse.y - Math.sin(angle) * (mouse.radius + this.size);
  
      this.x += (targetX - this.x) * 0.08; 
      this.y += (targetY - this.y) * 0.08; 
    } else {
      this.x += this.directionX;
      this.y += this.directionY;
    }
  
    this.draw(canvas.getContext("2d"));
  }
}

function init(canvas, particlesArray) {
  particlesArray = [];
  let numberOfParticles = (canvas.height * canvas.width) / 9000;
  for (let index = 0; index < numberOfParticles; index++) {
    const size = Math.random() * 5 + 1;
    let x =
      Math.random() * (window.innerWidth - size * 2 - size * 2) + size * 2;
    let y =
      Math.random() * (window.innerHeight - size * 2 - size * 2) + size * 2;
    let directionX = Math.random() * 5 - 2.5;
    let directionY = Math.random() * 5 - 2.5;
    let color = "background: radial-gradient(circle at 25%, red, black);";

    particlesArray.push(new Particle(x, y, directionX, directionY, size, color));
  }
}

function detectCollision(particle1, particle2) {
  const dx = particle1.x - particle2.x;
  const dy = particle1.y - particle2.y;
  const distance = Math.sqrt(dx * dx + dy * dy);
  return distance < particle1.size + particle2.size;
}



function animate(canvas, particlesArray, mouse) {
  requestAnimationFrame(() => animate(canvas, particlesArray, mouse));
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  for (let i = 0; i < particlesArray.length; i++) {
    const particle1 = particlesArray[i];
    particle1.update(canvas, mouse);

    for (let j = i + 1; j < particlesArray.length; j++) {
      const particle2 = particlesArray[j];

      if (detectCollision(particle1, particle2)) {
       
        const tempDirectionX = particle1.directionX;
        const tempDirectionY = particle1.directionY;

        particle1.directionX = particle2.directionX;
        particle1.directionY = particle2.directionY;

        particle2.directionX = tempDirectionX;
        particle2.directionY = tempDirectionY;
      }
    }
  }
}

export { Particle, init, animate };
