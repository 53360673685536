import "../App.css";
import IconDiv from "./Icondiv";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";

function Bottombar({ isMobile }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: isMobile ? "20vh" : "5vh",
        background: "rgba(0, 0, 0, 1)",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: "0",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", }}>
        <a style={{ fontSize: "20px", color: "white", margin: "5px" }}>©</a>
        <span style={{ fontSize: "16px", color: "#D91A1A", whiteSpace: isMobile ? "pre-line" : "nowrap" }}>2023 Felipe Dias Diniz</span>
      </div>
      <div
        style={{
          display: "flex",
          width: "auto",
          height: "auto",
          flexDirection: isMobile ? "column" : "row",
          gap: "1vh",
          justifyContent: "space-between",
        }}
      >
        <IconDiv text="felipediasdiniz@gmail.com" Icon={AlternateEmailIcon} path={"felipediasdiniz@gmail.com"} isEmail={true}/>
        <IconDiv text="linkedin.com/in/felipe-diniz-gm/" Icon={LinkedInIcon} path={"https://linkedin.com/in/felipe-diniz-gm/"} />
        <IconDiv text="github.com/gamemastergm" Icon={GitHubIcon} path={"https://github.com/gamemastergm"} />
      </div>
    </div>
  );
}

export default Bottombar;
